import React from "react";
import { css } from "@emotion/react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import PostCard from "../components/PostCard";
import SEO from "../components/SEO";

const technicalInterests = ['Design Patterns', 'Authentication Systems', 'Distributed Systems', 'Go', 'Ruby', 'JavaScript', 'Web Servers'];
const hobbyInterests = ['Woodworking', 'Wrenching on Machines', 'Backcountry Skiing', 'Mountain Biking', 'Raspberry Pis', 'Landscaping', 'Home Automation'];

const getRandomIndex = (arr) => 
  Math.round(Math.random() * (arr.length - 1));

export default function Home({ data }) {
  const { allMdx: mdx } = data;
  const technicalInterest = technicalInterests[getRandomIndex(technicalInterests)];
  const hobbyInterest = hobbyInterests[getRandomIndex(hobbyInterests)];

  return (
    <Layout>
      <SEO />
      <section css={css`
        margin-top: 2.25rem;
      `}>
        <p>
          Taylor is a software engineer with interests ranging from {technicalInterest} to {hobbyInterest}.
        </p>
      </section>
      <section>
        <h4>Blog</h4>
        {mdx.nodes.map((node) => (
          <PostCard key={node.id} post={node} />
        ))}
      </section>
    </Layout>
  );
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        id
        timeToRead
        frontmatter {
          author
          date
          title
        }
        excerpt
        fields {
          slug
        }
      }
      totalCount
    }
  }
`;
