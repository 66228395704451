import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";

import { rhythm } from "../utils/typography";

export default function PostCard({ post }) {
  return (
    <div css={css`
      padding-left: 0.5rem;
      border-left: 0.1rem solid #E76F51;
    `}>
      <Link
        to={post.fields.slug}
        css={css`
          text-decoration: none;
          color: inherit;
        `}
      >
        <h3
          css={css`
            margin-bottom: ${rhythm(1 / 4)};
          `}
        >
          {post.frontmatter.title}{" "}
          <span
            css={css`
              color: #bbb;
            `}
          >
            — {post.frontmatter.date}
          </span>
        </h3>
        <p
          css={css`
            font-weight: 200;
            font-size: 14px;
            font-style: italic;
            margin-bottom: 10px;
          `}
        >
          {post.timeToRead} minute read
        </p>
        <p>{post.excerpt}</p>
      </Link>
    </div>
  );
}
